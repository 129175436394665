export const menuRoles = {
    itensMenu: {
        home: {
            roles: ['HOME_HOME_VISUALIZAR'],
            home: ['HOME_HOME_VISUALIZAR']
        },
        freelancer: {
            roles: [
                'FREELANCER_FREELANCER_SALVAR',
                'FREELANCER_FREELANCER_DELETAR',
                'FREELANCER_FREELANCER_VISUALIZAR'
            ],
            freelancer: {
                visualizar: ['FREELANCER_FREELANCER_VISUALIZAR'],
                salvar: ['FREELANCER_FREELANCER_SALVAR'],
                deletar: ['FREELANCER_FREELANCER_DELETAR']
            }
        },
        contrato: {
            roles: [
                'CONTRATO_CONTRATO_SALVAR',
                'CONTRATO_CONTRATO_DELETAR',
                'CONTRATO_CONTRATO_VISUALIZAR'
            ],
            contrato: {
                visualizar: ['CONTRATO_CONTRATO_VISUALIZAR'],
                salvar: ['CONTRATO_CONTRATO_SALVAR'],
                deletar: ['CONTRATO_CONTRATO_DELETAR']
            }
        },
        parametros: {
            roles: [
                'PARAMETROS_CARGO_DELETAR',
                'PARAMETROS_CARGO_VISUALIZAR',
                'PARAMETROS_CARGO_SALVAR',
                'PARAMETROS_CATEGORIA_DELETAR',
                'PARAMETROS_CATEGORIA_VISUALIZAR',
                'PARAMETROS_CATEGORIA_SALVAR',
                'PARAMETROS_JUSTIFICATIVA_DELETAR',
                'PARAMETROS_JUSTIFICATIVA_VISUALIZAR',
                'PARAMETROS_JUSTIFICATIVA_SALVAR',
                'PARAMETROS_TREINAMENTO-OBRIGATORIO_DELETAR',
                'PARAMETROS_TREINAMENTO-OBRIGATORIO_VISUALIZAR',
                'PARAMETROS_TREINAMENTO-OBRIGATORIO_SALVAR',
                'PARAMETROS_REGISTRO-PROFISSIONAL_DELETAR',
                'PARAMETROS_REGISTRO-PROFISSIONAL_VISUALIZAR',
                'PARAMETROS_REGISTRO-PROFISSIONAL_SALVAR',
                'PARAMETROS_TIPO-CONTRATACAO_DELETAR',
                'PARAMETROS_TIPO-CONTRATACAO_VISUALIZAR',
                'PARAMETROS_TIPO-CONTRATACAO_SALVAR'
            ],
            cargo: [
                'PARAMETROS_CARGO_DELETAR',
                'PARAMETROS_CARGO_VISUALIZAR',
                'PARAMETROS_CARGO_SALVAR'
            ],
            categoria: [
                'PARAMETROS_CATEGORIA_DELETAR',
                'PARAMETROS_CATEGORIA_VISUALIZAR',
                'PARAMETROS_CATEGORIA_SALVAR'
            ],
            justificativa: [
                'PARAMETROS_JUSTIFICATIVA_DELETAR',
                'PARAMETROS_JUSTIFICATIVA_VISUALIZAR',
                'PARAMETROS_JUSTIFICATIVA_SALVAR'
            ],
            registroProfissional: [
                'PARAMETROS_REGISTRO-PROFISSIONAL_DELETAR',
                'PARAMETROS_REGISTRO-PROFISSIONAL_VISUALIZAR',
                'PARAMETROS_REGISTRO-PROFISSIONAL_SALVAR'
            ],
            tipoContratacao: [
                'PARAMETROS_TIPO-CONTRATACAO_DELETAR',
                'PARAMETROS_TIPO-CONTRATACAO_VISUALIZAR',
                'PARAMETROS_TIPO-CONTRATACAO_SALVAR'
            ],
            treinamentoObrigatorio: [
                'PARAMETROS_TREINAMENTO-OBRIGATORIO_DELETAR',
                'PARAMETROS_TREINAMENTO-OBRIGATORIO_VISUALIZAR',
                'PARAMETROS_TREINAMENTO-OBRIGATORIO_SALVAR'
            ],
            signatarios: [
                'PARAMETROS_SIGNATARIOS_DELETAR',
                'PARAMETROS_SIGNATARIOS_VISUALIZAR',
                'PARAMETROS_SIGNATARIOS_SALVAR'
            ]
        }
    }
};

export const rolesPersonalizadas = {
    rh: {
        visualizar: ['PERSONALIZADAS_RH_VISUALIZAR'],
        salvar: ['PERSONALIZADAS_RH_SALVAR']
    },
    seguranca: {
        visualizar: ['PERSONALIZADAS_SEGURANCA_VISUALIZAR'],
        salvar: ['PERSONALIZADAS_SEGURANCA_SALVAR']
    },
    ambularotio: {
        visualizar: ['PERSONALIZADAS_AMBULATORIO_VISUALIZAR'],
        salvar: ['PERSONALIZADAS_AMBULATORIO_SALVAR']
    },
    juridico: {
        visualizar: ['PERSONALIZADAS_JURIDICO_VISUALIZAR'],
        salvar: ['PERSONALIZADAS_JURIDICO_SALVAR']
    },
    saddb: {
        visualizar: ['PERSONALIZADAS_SADDB_VISUALIZAR'],
        salvar: ['PERSONALIZADAS_SADDB_SALVAR']
    }
};
