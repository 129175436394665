import { Inject, Injectable } from '@angular/core';
import { PERMISSOES_SERVICE_TOKEN, PermissoesService } from '@sbt-suite/components';

@Injectable({
    providedIn: 'root'
})
export class RolesService {
    constructor(@Inject(PERMISSOES_SERVICE_TOKEN) private _permissoesService: PermissoesService) {}

    hasPermission(roles: string[]): boolean {
        return roles.some((role) => this._permissoesService.checkPermissao(role));
    }
}
