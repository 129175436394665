import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { menuRoles, rolesPersonalizadas } from './core/constants/roles';
import { permissaoRotaGuard } from './core/guards/permissao-rota.guard';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home'
    },
    {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule),
        canActivate: [permissaoRotaGuard]
    },
    {
        path: 'freelancer',
        loadChildren: () =>
            import('./pages/freelancer/freelancer.module').then((m) => m.FreelancerModule),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.freelancer.freelancer.visualizar
        }
    },
    {
        path: 'dados-bancarios',
        loadChildren: () =>
            import('./pages/dados-bancarios/dados-bancarios.module').then(
                (m) => m.DadosBancariosModule
            ),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: rolesPersonalizadas.rh.visualizar
        }
    },
    {
        path: 'contrato',
        loadChildren: () =>
            import('./pages/contrato/contrato.module').then((m) => m.ContratoModule),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.contrato.contrato.visualizar
        }
    },
    {
        path: 'parametros/cargo',
        loadChildren: () =>
            import('./pages/parametros/cargo/cargo.module').then((m) => m.CargoModule),
        canActivate: [permissaoRotaGuard]
    },
    {
        path: 'parametros/justificativa',
        loadChildren: () =>
            import('./pages/parametros/justificativa/justificativa.module').then(
                (m) => m.JustificativaModule
            ),
        canActivate: [permissaoRotaGuard]
    },
    {
        path: 'parametros/registro-profissional',
        loadChildren: () =>
            import('./pages/parametros/registro-profissional/registro-profissional.module').then(
                (m) => m.RegistroProfissionalModule
            ),
        canActivate: [permissaoRotaGuard]
    },
    {
        path: 'parametros/tipo-contratacao',
        loadChildren: () =>
            import('./pages/parametros/tipo-contratacao/tipo-contratacao.module').then(
                (m) => m.TipoContratacaoModule
            ),
        canActivate: [permissaoRotaGuard]
    },
    {
        path: 'parametros/treinamento-obrigatorio',
        loadChildren: () =>
            import(
                './pages/parametros/treinamento-obrigatorio/treinamento-obrigatorio.module'
            ).then((m) => m.TreinamentoObrigatorioModule),
        canActivate: [permissaoRotaGuard]
    },
    {
        path: 'nao-autorizado',
        loadChildren: () =>
            import('./pages/sem-autorizacao/sem-autorizacao.module').then(
                (m) => m.SemAutorizacaoModule
            ),
        canActivate: [permissaoRotaGuard]
    },
    {
        path: 'relatorio',
        loadChildren: () =>
            import('./pages/relatorios/relatorio.module').then((m) => m.RelatorioModule)
    },
    {
        path: 'parametros/signatario',
        loadChildren: () =>
            import('./pages/parametros/signatario/signatario.module').then(
                (m) => m.SignatarioModule
            ),
        canActivate: [permissaoRotaGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
